.calendar-container {
  margin: 20px auto;
  width: 100%;
}

.react-calendar {
  width: 100% !important;
  border-radius: 8px !important;
}

.schedule-form {
  margin: 20px auto;
  width: 50%;
  text-align: left;
}

.schedule-form input,
.schedule-form textarea {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.schedule-form button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.schedule-form button:hover {
  background-color: #218838;
}

.schedule-list {
  margin: 20px auto;
  width: 50%;
  text-align: left;
}

.schedule-list ul {
  list-style-type: none;
  padding: 0;
}

.schedule-list li {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.schedule-chip {
  background-color: #007bff;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  margin-top: 2px;
  font-size: 12px;
}

.react-calendar__tile {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: end;
  border: 1px solid #ccc !important;
}

.react-calendar__tile--now {
  background: #d3e7fa !important;
  color: #000 !important;
}

.react-calendar__tile--now {
  background: #d3e7fa !important;
  color: #000 !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #d3e7fa !important;
  color: #000 !important;
}

.react-calendar__tile > div {
  overflow: auto;
}

abbr[title] {
  cursor: default !important;
}
