.consultText {
  color: #333;
  font-size: 20px;
}
.MainText {
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mainBottomMargin {
  margin-bottom: 77px !important;
}

.SubText {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.48px;
}
.mottomMargin45 {
  margin-bottom: 45px !important;
}
.subBottomMargin18 {
  margin-bottom: 18px !important;
}
.etcText {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.16px;
}

.labelText {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 6px;
}

.checkLabelText {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.customInput {
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
  transition: none !important;
  //   --bs-form-select-bg-img: url(../../assets/icon/svgs/solid/chevron-down.svg) !important;
}

.customInputLeft {
  border-top: 0.5px solid #6e737c !important;
  border-bottom: 0.5px solid #6e737c !important;
  border-left: 0.5px solid #6e737c !important;
  border-right: none !important;
  border-radius: 0 !important;
  transition: none !important;
}

.customInputRight {
  border-top: 0.5px solid #6e737c !important;
  border-bottom: 0.5px solid #6e737c !important;
  border-right: 0.5px solid #6e737c !important;
  border-left: none !important;
  border-radius: 0 !important;
  transition: none !important;
  //   --bs-form-select-bg-img: url(../../assets/icon/svgs/solid/chevron-down.svg) !important;
}

.customCheckBox {
  width: 21px !important;
  height: 21px !important;
  flex-shrink: 0 !important;
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
}

.customButton {
  border: none;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 27px 126px;
  border-radius: 0;
}

.customInput::placeholder {
  color: #bcbcbc !important;
}

.topPadding {
  padding-top: 85px !important;
  padding-bottom: 5rem !important;
}

.hopeMargin {
  margin-bottom: 80px !important;
}

.checkMarginTop {
  margin-top: 0px !important;
}
.checkMarginBottom {
  margin-top: 30px !important;
}

.customBtnWrap {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 30px !important;
}

.brcontroll {
  display: none;
}

.modalWrap {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalCard {
  white-space: pre-wrap;
  max-height: 100vh;
  overflow: auto;
}

.customSearchInputWrap {
  border: 1px solid #ced4da !important;
  border-radius: 0 !important;
}

.customSearchInput {
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0 !important;
}

.noborder {
  border: none !important;
}

@media (max-width: 991px) {
  .MainText {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mainBottomMargin {
    margin-bottom: 20px !important;
  }

  .SubText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.28px;
  }

  .etcText {
    color: #333;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.98px;
    letter-spacing: 0.22px;
    display: flex;
    justify-content: flex-end;
  }

  .labelText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.144px;
  }

  .checkLabelText {
    color: #333;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.223px;
    letter-spacing: -0.4px;
  }

  .customButton {
    border: none;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 17px 88px;
    border-radius: 0;
  }
  .topPadding {
    padding-top: 22px !important;
  }

  .mottomMargin45 {
    margin-bottom: 25px !important;
  }
  .subBottomMargin18 {
    margin-bottom: 8px !important;
  }
  .hopeMargin {
    margin-bottom: 17px !important;
  }
  .checkMarginTop {
    margin-top: 30px !important;
  }
  .checkMarginBottom {
    margin-top: 15px !important;
  }
  .customBtnWrap {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 8px !important;
  }
  .brcontroll {
    display: block;
  }
}
.image-container {
  position: relative;
  overflow: hidden;
}

.thumbnail {
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  padding-top: 60px;
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.customtable {
  position: relative;
}

.customTotalcount {
  position: sticky;
  left: 0;
}

// .customScroll::-webkit-scrollbar-thumb {
//   background: #0576b9;
// }

// .customScroll::-webkit-scrollbar-track {
//   background: #0576b9;
// }

// .customScroll::-webkit-scrollbar-thumb {
//   background: #0576b9;
// }

// .customScroll::-webkit-scrollbar-thumb:hover {
//   background: #0576b9;
// }
// .customScroll {
//   scrollbar-color: #0576b9 #f1f1f1;
// }
