.tableborder {
  border: 1px solid #dcdcdc !important;
}
.tableBorderRight {
  border-right: 1px solid #dcdcdc;
}
.tableNoCursor {
  cursor: default;
}

.tableWrapContainer {
  overflow: auto;
}
