.react-datepicker {
  width: 100% !important;
}

.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.hoursCardWrap {
  display: flex;
  flex-wrap: wrap;
}

.hoursCard {
  width: 130px;
  text-align: center;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}

.hoursDisabledCard {
  width: 130px;
  text-align: center;
  border: 1px solid #dcdcdc;
  background-color: #dcdcdc;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}
.react-datepicker__day--today {
  background-color: transparent; /* 배경 색상 제거 */
  color: inherit; /* 글자 색상을 기본 상속으로 설정 */
  font-weight: normal; /* 폰트 굵기 기본으로 설정 */
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent; /* 배경 색상 제거 */
  color: inherit; /* 글자 색상을 기본 상속으로 설정 */
  font-weight: normal; /* 폰트 굵기 기본으로 설정 */
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem !important;
  background-color: #d0d0d0 !important;
  color: #fff !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled {
  cursor: default !important;
}
