.review_lst{
  table{width: 100%;
    thead{background-color: #F2F2F2;
      tr{
        th{padding: 10px;text-align: center;}
      }
    }
    tbody{
      tr{border-bottom: 1px solid #ddd;
        td{padding: 10px;text-align: center;
          &.tit{text-align-last: left;}
          a{color: inherit;}
          img{width: 90px;}
        }
      }
    }
  }
}

.lst_thumnail{max-width: 100%;}