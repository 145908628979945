.descriptionText {
  color: crimson;
  margin-top: 4px;
}
.marginLeft10 {
  margin-left: 10px;
}
.marginLeft5 {
  margin-left: 5px;
}
.rowBorderBottom {
  border-bottom: 1px solid #ced4da;
}
.truncate {
  white-space: nowrap; /* 텍스트를 줄바꿈하지 않고 한 줄로 표시 */
  overflow: hidden; /* 내용이 넘칠 경우 숨김 처리 */
  text-overflow: ellipsis; /* 넘치는 텍스트를 ...로 표시 */
}

.MainText {
  color: #333;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mainBottomMargin {
  margin-bottom: 77px !important;
}

.SubText {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.48px;
}
.mottomMargin45 {
  margin-bottom: 45px !important;
}
.subBottomMargin18 {
  margin-bottom: 18px !important;
}
.etcText {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.16px;
}

.labelText {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 6px;
}

.checkLabelText {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
}

.customInput {
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
  transition: none !important;
  //   --bs-form-select-bg-img: url(../../assets/icon/svgs/solid/chevron-down.svg) !important;
}

.customInputLeft {
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
  transition: none !important;
}

.customInputRight {
  border-top: 0.5px solid #6e737c !important;
  border-bottom: 0.5px solid #6e737c !important;
  border-right: 0.5px solid #6e737c !important;
  border-left: none !important;
  border-radius: 0 !important;
  transition: none !important;
  //   --bs-form-select-bg-img: url(../../assets/icon/svgs/solid/chevron-down.svg) !important;
}

.customCheckBox {
  width: 21px !important;
  height: 21px !important;
  flex-shrink: 0 !important;
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
}

.customButton {
  border: none;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 27px 126px;
  border-radius: 0;
}

.customInput::placeholder {
  color: #bcbcbc !important;
}

.topPadding {
  padding-top: 85px !important;
  padding-bottom: 5rem !important;
}

.hopeMargin {
  margin-bottom: 80px !important;
}

.checkMarginTop {
  margin-top: 0px !important;
}
.checkMarginBottom {
  margin-top: 30px !important;
}

.customBtnWrap {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 30px !important;
}

.brcontroll {
  display: none;
}

.textLabel {
  text-wrap: nowrap;
}

.mr20 {
  margin-right: 20px;
}

.radiowrap {
  flex-wrap: wrap;
  align-items: center;
}
.checkboxwrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 각 항목 사이의 간격을 조정합니다. */
}

.checkbox-group {
  display: flex;
  align-items: center; /* 체크박스와 라벨을 수직 중앙 정렬합니다. */
}

.checkbox-group input[type="checkbox"] {
  margin-right: 5px; /* 체크박스와 라벨 사이의 간격을 조정합니다. */
}

.checkbox-group label {
  margin: 0; /* 라벨의 마진을 제거합니다. */
}
@media (max-width: 991px) {
  .MainText {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mainBottomMargin {
    margin-bottom: 20px !important;
  }

  .SubText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.28px;
  }

  .etcText {
    color: #333;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.98px;
    letter-spacing: 0.22px;
    display: flex;
    justify-content: flex-end;
  }

  .labelText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.144px;
  }

  .checkLabelText {
    color: #333;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.223px;
    letter-spacing: -0.4px;
  }

  .customButton {
    border: none;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 17px 88px;
    border-radius: 0;
  }
  .topPadding {
    padding-top: 22px !important;
  }

  .mottomMargin45 {
    margin-bottom: 25px !important;
  }
  .subBottomMargin18 {
    margin-bottom: 8px !important;
  }
  .hopeMargin {
    margin-bottom: 17px !important;
  }
  .checkMarginTop {
    margin-top: 30px !important;
  }
  .checkMarginBottom {
    margin-top: 15px !important;
  }
  .customBtnWrap {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 8px !important;
  }
  .brcontroll {
    display: block;
  }
}
