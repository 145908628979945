.modalWrap {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalCard {
  white-space: pre-wrap;
  max-height: 100vh;
  overflow: auto;
}

.modalCard1 {
  white-space: pre-wrap;
  overflow: auto;
  height: 100% !important;
}

.primiumMainText {
  margin-top: 0 !important;
  color: #333;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  word-break: keep-all;
  margin-bottom: 10px;
}

#primiumRejectBtn {
  padding: 20px 0px !important;
  border: none;
  border-radius: 8px !important;
  background: #f3f3f3 !important;
  gap: 10px !important;
  color: #4f4f4f !important;
  font-family: "Pretendard Variable" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  width: 100% !important;
}

#primiumAccesBtn {
  padding: 20px 0px !important;
  border: none;
  border-radius: 8px !important;
  background: #4f4f4f !important;
  gap: 10px !important;
  color: #f5f5f5 !important;
  font-family: "Pretendard Variable" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  width: 100% !important;
}
.border-table table, .border-table th, .border-table td{
  border: 1px solid rgb(116, 116, 116);
  border-collapse: collapse;
}
.border-table td{
  padding: 1px;
}

.border-table th{
  text-align: center;
}